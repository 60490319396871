<template>
  <WeContainer card="">
    <!-- Title -->
    <WeCard class="mb-3">
      <h5 class="custom-card-title">
        <i class="fas fa-bell"></i>
        <span>{{ $t("stockNotificationReports") }}</span>
      </h5>
    </WeCard>
    <!-- ./Title -->

    <!-- Search -->
    <WeTableSearch
      v-on:search="onSearch"
      v-on:clear="onFilterClear"
      v-bind:show-icon="false"
      header-class="bg-white text-dark"
      header-text-class="text-dark h6"
      v-bind:show-on-load="true"
    >
      <div slot="detail-search">
        <div class="row mb-3">
          <!-- Left -->
          <div class="col-12 col-lg-6">
            <!-- Created At -->
            <div class="d-block mb-3">
              <WeRowInput
                form="date"
                size="sm"
                label="Tarih"
                placeholder="Tarih"
                v-bind:date-range="true"
                v-model="filter.dates"
                selected-shortcut="thisMonth"
              />
            </div>
            <!-- Created At -->
          </div>
          <!-- Left -->
        </div>
      </div>
    </WeTableSearch>
    <!-- ./Search -->

    <!-- Content -->
    <WeCard>
      <div class="position-relative" style="min-height: 250px" v-if="loading">
        <WeLoading />
      </div>
      <WeTable
        v-else
        v-bind:index="false"
        v-bind:columns="columns"
        v-bind:data="list"
        v-bind:actions="actions"
        v-bind:exportables="exportables"
        v-on:on-export="onExport"
        v-on:on-action="onAction"
      />
    </WeCard>
    <!-- ./Content -->

    <Members
      v-if="showModal && productDetail"
      v-bind:detail="productDetail"
      v-bind:loading="modalLoading"
      v-on:close="onCloseDetail"
    />
  </WeContainer>
</template>
<script>
import { mapActions } from "vuex";
const Members = () => import("./views/Members/Index.vue");
export default {
  name: "StockNotification",
  components: {
    Members,
  },
  data() {
    return {
      loading: false,
      showModal: false,
      modalLoading: false,
      productDetail: null,
      list: [],
      actions: [
        {
          icon: "fas fa-edit",
          class: "btn-outline-primary",
          action: "edit",
          tooltip: "Ürünü Düzenle",
        },
        {
          icon: "fas fa-list",
          class: "btn-outline-indigo ml-2",
          action: "detail",
          tooltip: "Müşteri Listesi",
        },
      ],
      columns: [
        { name: "sku_no", th: "Stok Kodu", type: "string", width: "10%" },
        { name: "name", th: "Ürün Adı", type: "string" },
        {
          name: "notification_count",
          th: "Bildirim Sayısı",
          type: "integer",
          width: "15%",
        },
        {
          name: "quantity",
          th: "Mevcut Stok Miktarı",
          type: "integer",
          width: "15%",
        },
        {
          name: "is_active",
          th: "Ürün Durumu",
          type: "boolean_disabled",
          width: "15%",
        },
      ],
      exportables: ["excel"],
      filter: {
        dates: null,
      },
    };
  },
  methods: {
    ...mapActions("reports", ["makeSearch", "show"]),
    ...mapActions("tableExport", ["exportData"]),
    getFilter(copyDates = true) {
      let copy = helper.clone(this.filter);
      copy.dates = [];

      if (
        copyDates &&
        this.filter.dates &&
        this.filter.dates.start &&
        this.filter.dates.end
      ) {
        Object.entries(this.filter.dates).forEach(([key, value]) => {
          if (value) {
            copy.dates.push(value);
          }
        });
      } else {
        delete copy.dates;
      }

      return copy;
    },
    onSearch() {
      this.loading = true;
      setTimeout(() => {
        this.makeSearch({
          routeKey: "stockNotification",
          filter: this.getFilter(),
          onSuccess: (result) => {
            this.list = result.data.items;
            this.loading = false;
          },
        });
      }, 500);
    },
    onAction(data) {
      switch (data.key) {
        case "detail":
          this.showDetail(data.row);
          break;

        case "edit":
          this.$router.push(`/products/detail/${data.row.id}`);
          break;
      }
    },
    onFilterClear() {
      this.filter = {
        dates: {
          start: null,
          end: null,
        },
      };
      this.onSearch();
    },
    showDetail(row) {
      this.productDetail = {};
      this.productDetail.id = row.id;
      this.productDetail.title = row.sku_no;
      this.showModal = true;
      this.modalLoading = true;

      this.show({
        routeKey: "stockNotificationMember",
        id: row.id,
        filter: this.getFilter(),
        onSuccess: (result) => {
          this.productDetail.members = result.data.items;
          this.productDetail.filter = this.getFilter();
        },
        onFinish: () => {
          this.modalLoading = false;
        },
      });
    },
    onExport(data) {
      if (this.list && this.list.length) {
        let fileName = "Stok Bildirimi Raporları";

        if (
          this.filter.dates &&
          this.filter.dates.start &&
          this.filter.dates.end
        ) {
          let startDate = new Date(this.filter.dates.start).toLocaleDateString(
            "tr-TR"
          );
          let endDate = new Date(this.filter.dates.end).toLocaleDateString(
            "tr-TR"
          );

          fileName = startDate + " - " + endDate + " - " + fileName;
        }

        this.exportData({
          route: "report/stock-notifications/export",
          exportable: data,
          fileName: fileName,
          filter: {
            ...this.getFilter(),
            type: data.name,
          },
        });
      } else {
        this.$swal({
          title: "Dışarı aktarılacak veri mevcut değil",
          icon: "warning",
          confirmButtonText: "Tamam",
        });
      }
    },
    onCloseDetail() {
      this.showModal = false;
      this.productDetail = null;
    },
  },
  mounted() {
    this.onSearch();
  },
};
</script>